import clsx from 'clsx';
import Image from 'next/image';
import React, {FC} from 'react';

import {Theme, useMediaQuery} from '@mui/material';

import {
  SubcomponentsTeaserGridItemComponent,
  SubcomponentsTeaserGridSlideComponent,
  TypoComponentsTeaserGrid,
} from 'lib/api/strapi';

import {Box, Grid, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import StrapiLink from 'components/basic-components/StrapiLink';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import {Carousel} from './Carousel';
import styles from './TeaserGrid.module.scss';

const TeaserGrid: FC<TypoComponentsTeaserGrid> = ({
  teaserGridItem,
  teaserGridSlide,
}: {
  teaserGridItem: SubcomponentsTeaserGridItemComponent[];
  teaserGridSlide: SubcomponentsTeaserGridSlideComponent[];
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Box className={styles.wrapper}>
      <Box className={clsx({[styles.gridContainer]: true})}>
        <Grid container columnSpacing={{xxs: 0, md: '2%'}} rowSpacing={{xxs: '20px', md: 0}}>
          <Grid xxs={12} md={7}>
            <Carousel slides={teaserGridSlide} />
          </Grid>
          <Grid xxs={12} md={5}>
            {teaserGridItem?.map((item, index) => {
              return (
                <Box
                  key={item.id}
                  sx={{position: 'relative', marginBottom: index === 0 ? '20px' : null}}
                >
                  <StrapiLink
                    className={styles.imageLink}
                    style={{height: '100%'}}
                    linkProps={item.link}
                  >
                    <Box className={styles.background} />
                    <Image
                      priority={index === 0}
                      className={styles.imageSmall}
                      src={
                        isDesktop
                          ? item?.image?.data?.attributes?.url
                          : item?.imageMobile?.data?.attributes?.url
                          ? item?.imageMobile?.data?.attributes?.url
                          : item?.image?.data?.attributes?.url
                      }
                      sizes="(max-width: 769px) 100vw,  31vw"
                      height={
                        isDesktop
                          ? item?.image?.data?.attributes?.height
                          : item?.imageMobile?.data?.attributes?.height
                          ? item?.imageMobile?.data?.attributes?.height
                          : item?.image?.data?.attributes?.height
                      }
                      width={
                        isDesktop
                          ? item?.image?.data?.attributes?.width
                          : item?.imageMobile?.data?.attributes?.width
                          ? item?.imageMobile?.data?.attributes?.width
                          : item?.image?.data?.attributes?.width
                      }
                      alt=""
                      quality={80}
                    />
                    <Box className={styles.smallGridItemContentWrapper}>
                      {item?.subtitle ? (
                        <Typography className={styles.subtitle}>{item?.subtitle}</Typography>
                      ) : null}
                      <Typography className={styles.title} variant="h2">
                        {item?.title}
                      </Typography>
                      <Box className={styles.linkWrapper}>
                        <Typography className={styles.linkText} variant="buttonCards">
                          {item?.link?.linkText}
                        </Typography>
                        <CustomIcon name="arrow" className={styles.icon} />
                      </Box>
                    </Box>
                  </StrapiLink>
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorBoundary(TeaserGrid, ' TeaserGrid');
